
import './App.css';
import { InputGroup, Form, Button, Tab, Tabs, Col, Row, Nav, Navbar, Container} from 'react-bootstrap';
import { If, Then, Else, When, Unless, Switch, Case, Default } from 'react-if';
import Barcode from 'react-barcode';
import { FaPlus } from "react-icons/fa6";
import getFavList from './core/GetFav';
import { useState } from 'react';
import { useEffect } from 'react';
import { Card } from 'react-rainbow-components';
import {  deleteDoc, doc, addDoc, collection} from "firebase/firestore";
import initFirebase from './firebase/Init';
import { getFirestore } from 'firebase/firestore';
import { Modal } from 'react-rainbow-components';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

function FavTab() {
    initFirebase();
    const db = getFirestore();
    const [height, setHeight] = useState(window.innerHeight);
    const [isLoading, setIsLoading] = useState(true)
    const [isOpen, setIsOpen] = useState(false)
    const [favData, setFavData] = useState([])

    const [submitTitle, setSubmitTitle] = useState('')
    const [submitDescription, setSubmitDescription] = useState('')
    const [submitEve, setSubmitEve] = useState('')
    const [submitEan, setSubmitEan] = useState('')
    const [submitPrice, setSubmitPrice] = useState('')
    const [germanLetters, setGermanLetters] = useState([
        'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M',
        'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
        'Ä', 'Ö', 'Ü', 'ß'
      ]);


      const removeElement = (elementToRemove) => {
        const updatedArray = favData.filter(item => item !== elementToRemove);
        setFavData(updatedArray);
      };
    
    
      useEffect(() => {
        const updateWindowDimensions = () => {
          const newHeight = window.innerHeight;
          setHeight(newHeight);
        
        };
    
        window.addEventListener("resize", updateWindowDimensions);
        
      
          

        return () => window.removeEventListener("resize", updateWindowDimensions) 
    
      }, []);


      const handleDone = (uid, item) => {
        confirmAlert({
          
            message: 'Möchtest du dieses Produkt von deinen Favoriten löschen?',
            buttons: [
              {
                label: 'Ja',
                onClick: () => deleteDoc(doc(db, "fav", uid)).then(() => {
                    removeElement(item)
            
      
                }) 
              },
              {
                label: 'Nein',
                onClick: () => {}
              }
            ]
          });
       
       
       
    }
    
    const pushFavData =  (result) =>{

        var array = [];
        var i = 0;
        result.forEach((doc) => {
      
          
            array.push({docid: doc.id, pos: doc.data().pos, title:doc.data().title, short:doc.data().short,  short2:doc.data().short2, price:doc.data().price});
            i++;
            if(i == result.size){
                setFavData(array);
                console.log(array)
                
            }
          
        });
    
    }


    const Cards = (props) => {
        const cards = [];
        if (favData.length > 0) {
          for (let i = 0; i < favData.length; i++) {
            if(props.letter == "all"){
               
            cards.push(
                <Col>
            <Card style={{height: 400, paddingTop: 20, margin: 20, marginTop: 0}}>
                <div style={{position: "absolute", top: 5, right: 5, cursor: "pointer"}} onClick={() => {handleDone(favData[i]['docid'], favData[i])}}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill='#d9c63b' d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z"/></svg></div>
        <center>
          
            <span style={{fontSize: "1.2rem", fontWeight: "bold"}}>{favData[i]['title']}</span>
            <br/>
            <small>{favData[i]['short']}</small>
            <br/>
            <br/>
            <img
                src={'https://cdn1.interspar.at/cachableservlets/articleImage.dam/at/'+favData[i]['pos']+'/dt_main.jpg'}
               height={"120px"}
            />
            <br/>
            <small className='text-muted'>{favData[i]['short2']}</small>
            <br/>
            </center>
                <div style={{width: "100%", display: "flex",  justifyContent: "center", alignItems: "center", position: "absolute", bottom: 70}}>
                  
                    <Barcode value={favData[i]['pos']} width={1.2} height={50} fontSize={15} background="#FFFFFF"/>
                    
            </div>
          
            
        
            <div style={{backgroundColor: "#df021a", height: 60, width: "100%", position: "absolute", right: 0, bottom: 0, display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1.2rem", fontWeight: "bold", color: "#fff"}}>{favData[i]['price']}€</div>
        </Card>
            </Col>
             );
          }else{
// .charAt(0)
            if(favData[i]['title'].charAt(0).toLowerCase() == props.letter.toLowerCase() || favData[i]['short'].charAt(0).toLowerCase() == props.letter.toLowerCase()){
            cards.push(
                <Col>
            <Card style={{height: 400, paddingTop: 20, margin: 20, marginTop: 0}}>
                <div style={{position: "absolute", top: 5, right: 5, cursor: "pointer"}} onClick={() => {handleDone(favData[i]['docid'], favData[i])}}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill='#d9c63b' d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z"/></svg></div>
            <center>

            <span style={{fontSize: "1.2rem", fontWeight: "bold"}}>{favData[i]['title']}</span>
            <br/>
            <small>{favData[i]['short']}</small>
            <br/>
            <br/>
            <img
                src={'https://cdn1.interspar.at/cachableservlets/articleImage.dam/at/'+favData[i]['pos']+'/dt_main.jpg'}
            height={"120px"}
            />
            <br/>
            <small className='text-muted'>{favData[i]['short2']}</small>
            <br/>
            </center>
                <div style={{width: "100%", display: "flex",  justifyContent: "center", alignItems: "center", position: "absolute", bottom: 70}}>
                
                    <Barcode value={favData[i]['pos']} width={1.2} height={50} fontSize={15} background="#FFFFFF"/>
                    
            </div>



            <div style={{backgroundColor: "#df021a", height: 60, width: "100%", position: "absolute", right: 0, bottom: 0, display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1.2rem", fontWeight: "bold", color: "#fff"}}>{favData[i]['price']}€</div>
            </Card>
            </Col>
            );
          } 
        }
        }
        }
        return cards;
      }

useEffect(() => {
   
      const getFav = async () => {
          getFavList().then((result) => {
             pushFavData(result)
            
           })
      }
  
     
   Promise.all([getFav()]).then(setIsLoading(false))
  },[])


  const handleCreate = () => {
    if(submitDescription == "" || submitEan == "" ||  submitEve == "" ||  submitTitle == "" || submitPrice == ""){
      toast.error("Bitte fülle alle Felder aus!");
      
    }else{

    
    const isElementExists = favData.some(element => element.pos === submitEan);
    
    if (isElementExists) {
      console.log(`Element mit der Position ${submitEan} existiert bereits im Array.`);
    } else {
      setIsOpen(false)
        confirmAlert({
              
            message: 'Möchtest du dieses Produkt zu deinen Favoriten hizufügen?',
            buttons: [
              {
                label: 'Ja',
                onClick: () => addDoc(collection(db, "fav"), {
                    pos: submitEan,
                    title: submitTitle,
                    short: submitDescription, 
                    short2: ""+submitEve,
                    price: submitPrice
                  }).then(() => {
                    
                    getFavList().then((result) => {
                        pushFavData(result)
                        
                      })
            
                
                  })
              },
              {
                label: 'Nein',
                onClick: () => {}
              }
            ]
          });
    }
        
  }
       
    }
  
  if(isLoading){
    return (
        <>
    
           
    
    
    
        <Container fluid style={{paddingTop: 20, width: "100vw", height: "100vh", display: "flex"}}>
        a
         
    </Container>
       
        </>
      );
  }else{
  return (
    <>



<Tab.Container id="left-tabs-example" defaultActiveKey="all">

          <Nav variant="pills" >
          <Nav.Item>
              <Nav.Link eventKey="all">ALLE</Nav.Link>
            </Nav.Item>
          {germanLetters.map((letter, index) => (
             <Nav.Item key={index}>
             <Nav.Link eventKey={index}>{letter}</Nav.Link>
            
           </Nav.Item>
        ))}
            <Nav.Item style={{backgroundColor: "#cd0304"}}>
            <Nav.Link onClick={() => {
              setIsOpen(true)
              setSubmitDescription("")
          setSubmitEan("")
           setSubmitEve("")
            setSubmitPrice("")
             setSubmitTitle("")
              }}>+</Nav.Link>
            </Nav.Item>
        
            
            
          </Nav>
          
          <Tab.Content>
            <Tab.Pane eventKey="all">
            <Container fluid style={{paddingTop: 20, height: height-160, overflow: "scroll"}}>
    <Row xs={1} md={3} lg={3}>
           
           <Cards letter='all'/>
                 
               </Row>
</Container>
            </Tab.Pane>
            {germanLetters.map((letter, index) => (
                <Tab.Pane eventKey={index} key={index}> 
                <Container fluid style={{paddingTop: 20, height: height-160, overflow: "scroll"}}>
                <Row xs={1} md={3} lg={3}>
           
                <Cards letter={letter}/>
                </Row></Container>
                </Tab.Pane>
             
        ))}
            
          </Tab.Content>
        
       
    </Tab.Container>



    <Modal id="modal-1" isOpen={isOpen} onRequestClose={() => {setIsOpen(false)}} style={{padding: 40}}>
    <Form.Control
 style={{textAlign: "center"}}
    placeholder='MARKE'
       onChange={e => {setSubmitTitle(e.target.value)}}
   />
    <br/>
    <Form.Control
       
      style={{textAlign: "center"}}
       onChange={e => {setSubmitDescription(e.target.value)}}
       placeholder='BEZEICHNUNG'
   
   />
    <br/>
    <Form.Control
       placeholder='EVE'
       style={{textAlign: "center"}}
       onChange={e => {setSubmitEve(e.target.value)}}
    
      
   />
    <br/>
     <Form.Control
        style={{textAlign: "center"}}
        placeholder='EAN'
        onChange={e => {setSubmitEan(e.target.value)}}
       
       
    />
    <br/>
     <Form.Control
        style={{textAlign: "center"}}
        placeholder='PREIS'
        onChange={e => {setSubmitPrice(e.target.value)}}
        
       
    />

      <Button style={{width: "100%", marginTop: 30}} className='buttonNavSmall' onClick={() => {
        handleCreate()
         
        }}>HINZUFÜGEN</Button>
                </Modal>
                <ToastContainer
position="bottom-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
 
    </>
  );
  }
}

export default FavTab;
