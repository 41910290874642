
import './App.css';
import React from 'react';
import { useEffect } from 'react';
import { InputGroup, Form, Button , Tab, Tabs, Col, Row, Nav, Navbar, Container, Pagination, FormControl} from 'react-bootstrap';
import { If, Then, Else, When, Unless, Switch, Case, Default } from 'react-if';
import { Card } from 'react-rainbow-components';
import Barcode from 'react-barcode';
import {  deleteDoc, doc, addDoc, collection} from "firebase/firestore";
import initFirebase from './firebase/Init';
import { getFirestore } from 'firebase/firestore';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import getFavList from './core/GetFav';
import { TbListSearch } from "react-icons/tb";


import { useState } from 'react';

function SearchTab() {
    initFirebase();
  const db = getFirestore();
 
  const [favData, setFavData] = useState([])
    const [height, setHeight] = useState(window.innerHeight);
    const [searchData, setSearchData] = React.useState([]);
    const [searchQuery, setSearchQuery] = React.useState("")
    const [searchInput, setSearchInput] = React.useState("")
    const [searchPage, setSearchPage] = React.useState(1)
    const getSearchData = async (query, page) => {
        setSearchQuery(query)
        setSearchPage(page)
        return fetch('https://api.mydashboard.at/?search&q=' + query + '&p=' + page)
          .then(response => response.json())
          .then(data => {
            setSearchData(data);
        
            
          })
          .catch((error) => {
            console.error(error);
          });
      }

      const pushFavData =  (result) =>{

        var array = [];
        var i = 0;
        result.forEach((doc) => {
      
          
            array.push({docid: doc.id, pos: doc.data().pos, title:doc.data().title, short:doc.data().short,  short2:doc.data().short2, price:doc.data().price});
            i++;
            if(i == result.size){
                setFavData(array);
                console.log(array)
                
            }
          
        });
    
    }

    useEffect(() => {
        const updateWindowDimensions = () => {
          const newHeight = window.innerHeight;
          setHeight(newHeight);
        
        };
    
        window.addEventListener("resize", updateWindowDimensions);
        
       
            getFavList().then((result) => {
               pushFavData(result)
              
             })
        
          

        return () => window.removeEventListener("resize", updateWindowDimensions) 
    
      }, []);


const handleCreate = (pos, title, short, short2, price) => {

const isElementExists = favData.some(element => element.pos === pos);

if (isElementExists) {
  console.log(`Element mit der Position ${pos} existiert bereits im Array.`);
} else {
    confirmAlert({
          
        message: 'Möchtest du dieses Produkt zu deinen Favoriten hizufügen?',
        buttons: [
          {
            label: 'Ja',
            onClick: () => addDoc(collection(db, "fav"), {
                pos: pos,
                title: title,
                short: short, 
                short2: ""+short2,
                price: price
              }).then(() => {
                
                getFavList().then((result) => {
                    pushFavData(result)
                   
                  })
        
            
              })
          },
          {
            label: 'Nein',
            onClick: () => {}
          }
        ]
      });
}
    

   
}



      const CardsPagination = () => {

        const pagination = [];
        if (parseInt(searchData['totalHits']) > 0) {
          var current = parseInt(searchData['paging']['currentPage']);
          var total = parseInt(searchData['paging']['pageCount']);
    
          if (current > 1) {
            pagination.push(<Pagination.Prev onClick={() => {getSearchData(searchQuery, searchPage-1).catch(console.error);}} />);
          }
          pagination.push(<Pagination.Item disabled>{current}</Pagination.Item>);
          if (current < total) {
            pagination.push(<Pagination.Next onClick={() => {getSearchData(searchQuery, searchPage+1).catch(console.error);}} />);
    
          }
        }
        return pagination;
      }


     const Cards = () => {
        const cards = [];
        if (searchData['hits']) {
          for (let i = 0; i < searchData['hits'].length; i++) {
            
    
            cards.push(
                <Col>
            <Card style={{height: 400, paddingTop: 20, margin: 20, marginTop: 0}}>

                <If condition={favData.some(element => element.pos === searchData['hits'][i]['masterValues']['product-number'])}>
                    <Then>
                    <div style={{position: "absolute", top: 5, right: 5, cursor: "pointer"}} ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill='#d9c63b' d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z"/></svg></div>
       
                    </Then>
                    <Else>
                    <div style={{position: "absolute", top: 5, right: 5, cursor: "pointer"}} onClick={() => {handleCreate(searchData['hits'][i]['masterValues']['product-number'], searchData['hits'][i]['masterValues']['title'], searchData['hits'][i]['masterValues']['short-description-2'], searchData['hits'][i]['masterValues']['short-description-3'], searchData['hits'][i]['masterValues']['regular-price'].toFixed(2))}}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill='lightgray' d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z"/></svg></div>
       
                    </Else>
                </If>
                 <center>
            <span style={{fontSize: "1.2rem", fontWeight: "bold"}}>{searchData['hits'][i]['masterValues']['title']}</span>
            <br/>
            <small>{searchData['hits'][i]['masterValues']['short-description-2']}</small>
            <br/>
            <br/>
            <img
                src={searchData['hits'][i]['masterValues']['image-url']}
               height={"120px"}
            />
            <br/>
            <small className='text-muted'>{searchData['hits'][i]['masterValues']['short-description-3']}</small>
            <br/>
            </center>
                <div style={{width: "100%", display: "flex",  justifyContent: "center", alignItems: "center", position: "absolute", bottom: 70}}>
                  
                    <Barcode value={searchData['hits'][i]['masterValues']['product-number']} width={1.2} height={50} fontSize={15} background="#FFFFFF"/>
                    
            </div>
          
            
        
            <div style={{backgroundColor: "#df021a", height: 60, width: "100%", position: "absolute", right: 0, bottom: 0, display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1.2rem", fontWeight: "bold", color: "#fff"}}>{searchData['hits'][i]['masterValues']['regular-price'].toFixed(2)}€</div>
        </Card>
            </Col>
             );
          }
        }
        return cards;
      }
      
  return (
    <>

       

<Nav fixed="top" style={{padding: 20, paddingBottom: 0}}>
      <InputGroup className="mb-3" size='lg'>
        <FormControl
          placeholder="SUCHE PRODUKT, STICHWORT, THEMA ..."
         onChange={e => {setSearchInput(e.target.value)}} onKeyPress={(event) => {if(searchInput !== ""){if (event.key === "Enter") {getSearchData(searchInput, 1).catch(console.error);}}}}
        />
        <Button variant='dark' onClick={() =>{getSearchData(searchInput, 1).catch(console.error);}}>
          SUCHE
        </Button>
      </InputGroup>
      </Nav>
<If condition={searchData['totalHits'] > 0 }>
  <Then>
  <Container fluid style={{paddingTop: 20, height: height-160, overflow: "scroll"}}>


<If condition={searchQuery == ""}>
    <Then></Then>
    <Else><center><h6 className='text-muted'>{searchData['totalHits']} Suchergebnisse für <b>{searchQuery}</b></h6></center><br/>
      </Else>
</If>
    <Row xs={1} md={3} lg={3}>
           
      <Cards/>
            
          </Row>


      
          <div style={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%"}}><Pagination size="lg" className='app-pagination'>
            <CardsPagination />
          </Pagination></div>
                
      </Container>
  </Then>
  <Else>
  <Container fluid style={{paddingTop: 20, height: height-250, display: "flex", justifyContent: "center", alignItems: "center"}}>
  <TbListSearch style={{fontSize: "10rem", color: "lightgray"}} />
   
    </Container>
  </Else>
</If>
    

   
    </>
  );
}

export default SearchTab;
