
import './App.css';
import { InputGroup, Form, Button , Col, Row, Navbar, Container} from 'react-bootstrap';
import { If, Then } from 'react-if';

import SearchTab from './Search';

import { useState } from 'react';
import FavTab from './Fav';

function App() {

  const [searchTab, setSearchTab] = useState(true);
  const [favTab, setFavTab] = useState(false);

  const selectTab = (id) => {
    if( id == 1){
      setFavTab(false)
      setSearchTab(true)
    }else if(id == 2){
      setFavTab(true)
      setSearchTab(false)
    }
  }
  return (
    <>

        <If condition={favTab}>
          <Then>
            <FavTab/>
          </Then>
        </If>

        <If condition={searchTab}>
          <Then>
            <SearchTab/>
          </Then>
        </If>



    

      <Navbar className="bg-body-tertiary" fixed="bottom" style={{margin: 0, padding: 0}} >
        <Container fluid style={{margin: 0, padding: 0}} >
        <Container fluid style={{margin: 0, padding: 0}} >
      <Row style={{backgroundColor: "blue"}}>
        <Col><Button onClick={() => {selectTab(1)}} style={{width: "100%"}} className={searchTab ? "buttonNavSelected" : "buttonNav"} size="lg"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill='#ffffff' d="M23.832 19.641l-6.821-6.821c2.834-5.878-1.45-12.82-8.065-12.82-4.932 0-8.946 4.014-8.946 8.947 0 6.508 6.739 10.798 12.601 8.166l6.879 6.879c1.957.164 4.52-2.326 4.352-4.351zm-14.886-4.721c-3.293 0-5.973-2.68-5.973-5.973s2.68-5.973 5.973-5.973c3.294 0 5.974 2.68 5.974 5.973s-2.68 5.973-5.974 5.973z"/></svg></Button></Col>
       
        <Col><Button onClick={() => {selectTab(2)}} style={{width: "100%"}} className={favTab ? "buttonNavSelected" : "buttonNav"} size="lg"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill='#ffffff' d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z"/></svg></Button></Col>
      </Row>
      
    </Container>
        </Container>
      </Navbar>
    </>
  );
}

export default App;
