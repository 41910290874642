
import { initializeApp } from "firebase/app";


const firebaseConfig = {
  apiKey: "AIzaSyCeOMGIyXp179MK72Xtn1J8XM9nlOyxEuw",
  authDomain: "sparsearch-15b5d.firebaseapp.com",
  projectId: "sparsearch-15b5d",
  storageBucket: "sparsearch-15b5d.appspot.com",
  messagingSenderId: "46376313075",
  appId: "1:46376313075:web:05119561495db952ca1786",
  measurementId: "G-2XHP8Y2WCK"
};


const initFirebase = () => {
    const app = initializeApp(firebaseConfig);
}

export default initFirebase;