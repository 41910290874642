import initFirebase from "../firebase/Init";

import { getFirestore, collection, query, getDocs, orderBy} from "firebase/firestore";

export default function getFavList () {
   
        initFirebase();
    
        return new Promise((resolve) => {
            const db = getFirestore();
           
              
              const querySnapshot = getDocs(query(collection(db, "fav"), orderBy("title"),));
              if (querySnapshot) {
                  resolve(querySnapshot)
                } else {
                  resolve(null)
              }
           
            
        });
}